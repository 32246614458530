import { FormHelperText } from "@mui/material";

type ErrorMessageProps = {
  message?: string;
};

export const ErrorMessage = ({ message }: ErrorMessageProps) => (
  <FormHelperText error={true} sx={{ textAlign: "center" }}>
    {message}
  </FormHelperText>
);
